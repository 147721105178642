// src/App.js
import React, { useState, useEffect } from "react";
import "./index.css";

export const App = () => {
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [selectedTab, setSelectedTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);
  const [allAttempted, setAllAttempted] = useState(false);
  const questionsPerPage = 2;

  useEffect(() => {
    generateQuestions(selectedTab);
  }, [selectedTab]);

  const generateQuestions = (operationType) => {
    let operations;
    if (operationType === "All") {
      operations = ["+", "-", "*", "/"];
    } else {
      operations = [
        operationType === "Addition"
          ? "+"
          : operationType === "Subtraction"
          ? "-"
          : operationType === "Multiplication"
          ? "*"
          : "/",
      ];
    }

    const newQuestions = Array.from({ length: 10 }, (_, index) => {
      const num1 = Math.floor(Math.random() * 10) + 1;
      const num2 = Math.floor(Math.random() * 10) + 1;
      const operation =
        operations[Math.floor(Math.random() * operations.length)];
      const questionText = `${num1} ${operation} ${num2}`;
      const answer = eval(`${num1}${operation}${num2}`);

      const options = Array.from({ length: 4 }, () =>
        Math.floor(Math.random() * 100)
      );
      options[Math.floor(Math.random() * 4)] = answer;

      return {
        id: index,
        questionText,
        answer,
        options,
        operation,
      };
    });

    setQuestions(newQuestions);
    setUserAnswers({});
    setScore(null);
    setCurrentPage(0); // Reset page when questions are regenerated
  };

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers((prev) => {
      const newUserAnswers = { ...prev, [questionId]: answer };

      // Check if all questions have been attempted
      const allQuestionsAnswered = questions.every(
        (question) => newUserAnswers[question.id] !== undefined
      );
      setAllAttempted(allQuestionsAnswered);

      return newUserAnswers;
    });
  };

  const handleSubmit = () => {
    let score = 0;
    questions.forEach((question) => {
      if (userAnswers[question.id] === question.answer) {
        score++;
      }
    });
    setScore(score);
  };

  const handleNext = () => {
    if (currentPage < Math.floor(questions.length / questionsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const paginatedQuestions = questions.slice(
    currentPage * questionsPerPage,
    currentPage * questionsPerPage + questionsPerPage
  );

  return (
    <div className="p-4 max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-4">Math Quiz</h1>

      {/* Responsive Tabs */}
      <div className="flex flex-wrap justify-center gap-4 mb-4">
        {["All", "Addition", "Subtraction", "Multiplication", "Division"].map(
          (tab) => (
            <button
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`px-4 py-2 font-semibold rounded-lg ${
                selectedTab === tab
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              } hover:bg-blue-400 transition duration-300`}
            >
              {tab}
            </button>
          )
        )}
      </div>

      {paginatedQuestions.map((question) => (
        <div
          key={question.id}
          className="mb-4 p-2 bg-gray-100 rounded-lg shadow-md"
        >
          <p className="font-medium text-lg mb-3">
            <strong>Q{question.id + 1}:</strong> {question.questionText}
          </p>
          <div className="space-y-2">
            {question.options.map((option, index) => (
              <label key={index} className="block">
                <input
                  type="radio"
                  name={`question-${question.id}`}
                  value={option}
                  checked={userAnswers[question.id] === option}
                  onChange={() => handleAnswerChange(question.id, option)}
                  className="mr-2"
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}

      <div className="flex justify-between mt-6">
        <button
          onClick={handlePrev}
          disabled={currentPage === 0}
          className={`px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300 ${
            currentPage === 0 && "opacity-50 cursor-not-allowed"
          }`}
        >
          Previous
        </button>
        {score !== null && (
          <h2 className="text-2xl font-bold text-center ">
            Your score: {score} / 10
          </h2>
        )}
        {currentPage >= Math.floor(questions.length / questionsPerPage) - 1 ? (
          <button
            onClick={handleSubmit}
            className={`px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition duration-300 ${
              !allAttempted && "opacity-50 cursor-not-allowed"
            }`}
            disabled={!allAttempted}
          >
            Submit
          </button>
        ) : (
          <button
            onClick={handleNext}
            disabled={
              currentPage >= Math.floor(questions.length / questionsPerPage) - 1
            }
            className={`px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300 ${
              currentPage >=
                Math.floor(questions.length / questionsPerPage) - 1 &&
              "opacity-50 cursor-not-allowed"
            }`}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};
